import React from "react";
import usePrefersReducedMotion from "storefront/hooks/usePrefersReducedMotion";
import Props from "./Props";
import WantsMotion from "./WantsMotion";
import ReducedMotion from "./ReducedMotion";

/**
 * @description This component will render an autoplaying, looping video. This is a common pattern
 * for background videoes, animated icons, etc. This component is aware of whether the visitor has
 * indicated that they prefer reduced motion and respects that by displaying a poster image instead
 * of a video.
 *
 * Additionally, this component is designed to behave similarly to the <picture /> element; you
 * provide an array of sources and a single fallback, and the component will render the appropriate
 * video (or picture) based on the sources' media queries.
 *
 * If you server-side render this component, a poster image will be displayed until the component
 * is rendered on the client-side.
 */
const AutoplayVideo = (props: Props) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  switch (prefersReducedMotion) {
    case "no-preference":
      return <WantsMotion {...props} />;

    case "unknown":
    case "reduce":
    default: {
      return <ReducedMotion {...props} />;
    }
  }
};

export default AutoplayVideo;
