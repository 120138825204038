import React from "react";
import classNames from "classnames";
import styles from "./LinkButton.module.scss";

import { getLinkStyles, LinkVariant } from "../Link";

/**
 * It's a button that looks like a link!
 * On its own does not have any text styles, will inherit.
 */
type Props = {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  variant?: LinkVariant;
  disabled?: boolean;
};

const LinkButton = ({
  children,
  onClick,
  className,
  variant = "default",
  disabled = false,
}: Props) => (
  <button
    className={classNames(getLinkStyles(variant), styles.linkButton, className)}
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    {children}
  </button>
);

export default LinkButton;
