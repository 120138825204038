export const WOMENSWEAR = "womenswear" as const;

export const MENSWEAR = "menswear" as const;

export type Department = typeof WOMENSWEAR | typeof MENSWEAR;

export const hasWomenswearDepartment = (
  departments: Array<Department>,
): boolean => departments.includes(WOMENSWEAR);

export const hasMenswearDepartment = (
  departments: Array<Department>,
): boolean => departments.includes(MENSWEAR);

export const isDepartment = (str: string): str is Department =>
  str === WOMENSWEAR || str === MENSWEAR;

export const getDepartmentSeoTitle = (department: Department): string =>
  department === WOMENSWEAR ? "Women's" : "Men's";
