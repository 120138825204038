import React from "react";

type Props = {
  children?: React.ReactNode;
};

const Body = ({ children }: Props) => (
  <div className="Modal-Body">{children}</div>
);

export default Body;
