import { Id } from "storefront/lib/Id";
import { getVisitorId } from "storefront/lib/VisitorId";
import { Department } from "storefront/Department";
import { UserSelf } from "storefront/User";
import Platform, { fromWindow as platformFromWindow } from "./Platform";

/**
 * @description These are the properties we use when the visitor is identified upon finding out
 * whether they are authenticated. This happens whenever the visitor visits any page of the site.
 */
type VisitorProperties = {
  platform: Platform;

  // https://ryanve.com/lab/dimensions/
  deviceWidth: number;
  deviceHeight: number;
  devicePixelRatio: number;
  viewportWidth: number;
  viewportHeight: number;

  temp_pseudo_anonymous_id: Id;

  preferred_departments: Array<Department>;
};

export default VisitorProperties;

/**
 * @description Creates VisitorProperties from a Window and optionally a UserSelf.
 */
export const from = (
  win: typeof window,
  user?: UserSelf,
): VisitorProperties => ({
  platform: platformFromWindow(win),
  deviceWidth: win.screen.width,
  deviceHeight: win.screen.height,
  devicePixelRatio: win.devicePixelRatio,
  viewportWidth: win.document.documentElement.clientWidth,
  viewportHeight: win.document.documentElement.clientHeight,
  temp_pseudo_anonymous_id: getVisitorId(win),
  preferred_departments: user?.accountPreferences?.departments || [],
});
