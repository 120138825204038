import React, { Suspense, useState, useEffect } from "react";

type Props = {
  children: React.ReactNode;
};

/**
 * @name SuspenseWithFragment
 * @description Wraps the React Component with React.Suspense and Fragment Fallback while loading.
 */
const SuspenseWithFragment = ({ children }: Props) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useEffect(() => setIsMounted(true), []);
  return isMounted ? <Suspense fallback={<></>}>{children}</Suspense> : <></>;
};

export default SuspenseWithFragment;
