// This is inspired by ReduxForm's Meta Props
// https://redux-form.com/8.3.0/docs/api/field.md/#meta-props
export type Meta<Value> = {
  active: boolean;
  autofilled: boolean;
  asyncValidating: boolean;
  dirty: boolean;

  dispatch: ((...args: Array<any>) => any) | null | undefined;
  error: string | null | undefined;
  form: string | null | undefined;
  initial: Value | null | undefined;
  invalid: boolean;
  pristine: boolean;
  submitting: boolean;
  submitFailed: boolean;
  touched: boolean;
  valid: boolean;
  visited: boolean;
  warning: string | null | undefined;
};
export const empty: Meta<any> = {
  active: false,
  autofilled: false,
  asyncValidating: false,
  dirty: false,
  dispatch: null,
  error: null,
  form: null,
  initial: null,
  invalid: false,
  pristine: false,
  submitting: false,
  submitFailed: false,
  touched: false,
  valid: false,
  visited: false,
  warning: null,
};
