import type { OperatingSystem } from "./OperatingSystem";

type Browser = "GrailedWebView" | "Chrome" | "Edge";

/**
 * @private
 * @description A mapping of operating systems to regexes that can be used to test user agent
 * strings to see if the user agent is from the operating system.
 */
const OPERATING_SYSTEM_USER_AGENT_REGEXES: Record<OperatingSystem, RegExp> = {
  iOS: /iPhone|iPod|iOS/i,
  Android: /Android/i,
};

/**
 * @private
 * @name Navigator.isOperatingSystem
 * @description Given an Operating System and a Navigator, returns true if the given Operating
 * System is the current Operating System, or false if it isn't, based on the User Agent.
 */
const isOperatingSystem: (
  arg0: OperatingSystem,
) => (arg0: Navigator) => boolean = (os) => (navigator) =>
  OPERATING_SYSTEM_USER_AGENT_REGEXES[os].test(navigator.userAgent);

/**
 * @name Navigator.isIOS
 * @description Given a Navigator, returns true if the current Operating System is iOS, otherwise
 * returns false.
 */
export const isIOS: (arg0: Navigator) => boolean = isOperatingSystem("iOS");

/**
 * @private
 * @description A mapping of browsers to regexes that can be used to test user agent strings to see
 * if the user agent is from the browser.
 */
const BROWSER_USER_AGENT_REGEXES: Record<Browser, RegExp> = {
  GrailedWebView: /Grailed/i,
  Chrome: /Chrome/i,
  Edge: /Edge/i,
};

/**
 * @private
 * @name Navigator.isBrowser
 * @description Given a Browser and a Navigator, returns true if the given Browser is the current
 * Browser, or false if it isn't, based on the User Agent.
 */
const isBrowser: (arg0: Browser) => (arg0: Navigator) => boolean =
  (browser) => (navigator) =>
    BROWSER_USER_AGENT_REGEXES[browser].test(navigator.userAgent);

/**
 * @name Navigator.isGrailedWebView
 * @description Given a Navigator, returns true if the current Browser is a Grailed Web View,
 * otherwise returns false.
 */
const isGrailedWebView: (arg0: Navigator) => boolean =
  isBrowser("GrailedWebView");

/**
 * @name Navigator.isWebView
 * @description Given a Navigator, returns true if the current Browser is an app's Web View
 */
const isWebView: (arg0: Navigator) => boolean = (navigator) =>
  isGrailedWebView(navigator);

/**
 * @name Navigator.isIOSApp
 * @description Given a Navigator, returns true if the current Browser is an iOS Web View
 */
export const isIOSApp: (arg0: Navigator) => boolean = (navigator) =>
  isIOS(navigator) && isWebView(navigator);
