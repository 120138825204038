import React from "react";
import type { Icon } from "../../../Icon";

const ProtectionBadge = ({ className, height, width }: Icon) => (
  <svg
    className={className}
    height={height || "14px"}
    width={width || "14px"}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66228 1L1.5 2.72076V6.10612C1.5 9.29309 3.59611 12.0943 6.64079 13H7.35921C10.4039 12.0943 12.5 9.29309 12.5 6.10612V2.72076L7.33772 1H6.66228ZM7.5 0H6.5L0.5 2V6.10612C0.5 9.78637 2.95402 13.015 6.5 14H7.5C11.046 13.015 13.5 9.78637 13.5 6.10612V2L7.5 0Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65483 9.8836L8.62586 9.34193C8.46222 9.54079 8.27705 9.68967 8.06987 9.79507C7.78192 9.94155 7.45935 10 7.10758 10C6.21645 10 5.515 9.68549 5.02129 9.15582C4.52952 8.62011 4.25 7.87749 4.25 7.01552C4.25 6.06581 4.59451 5.22653 5.21991 4.66623C5.70618 4.23352 6.34124 4 7.13149 4C7.87033 4 8.46191 4.19817 8.89397 4.54587C9.3261 4.89363 9.5892 5.38339 9.68471 5.94953L9.7069 6.08105H8.34607L8.32521 5.99345C8.26651 5.74695 8.14154 5.53992 7.952 5.39416C7.76265 5.24854 7.50039 5.15758 7.1554 5.15758C6.65409 5.15758 6.28657 5.35802 6.04073 5.68375C5.79174 6.01364 5.66185 6.48044 5.66185 7.01552C5.66185 7.56333 5.8103 8.02808 6.06708 8.35306C6.32185 8.6755 6.6868 8.8657 7.13946 8.8657C7.92386 8.8657 8.43637 8.32557 8.48565 7.80384H7.11887V6.70834H9.75V9.8836H8.65483Z"
      fill="black"
    />
  </svg>
);

export default ProtectionBadge;
