import { parseDomain } from "storefront/lib/url";
import contentfulImageSrc from "./contentfulImageSrc";
import grailedImageSrc from "./grailedImageSrc";
import type { Params as ContentfulParams } from "./contentfulImageSrc";
import type { Params as GrailedParams } from "./grailedImageSrc";

export type SrcParams = ContentfulParams | GrailedParams;

const imageSrc = (url: string, params: SrcParams = {}): string => {
  const domain = parseDomain(url);

  switch (domain) {
    case "images.ctfassets.net":
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return contentfulImageSrc(url, params as ContentfulParams);
    case "external-media.grailed.com":
    case "media.grailed.com":
    case "media-assets.grailed.com":
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return grailedImageSrc(url, params as GrailedParams);
    default:
      return url;
  }
};

export default imageSrc;
