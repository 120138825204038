import type { AlgoliaIndexMapping } from "./index";
import "./index";
import hasIndexKey from "./hasIndexKey";

/**
 * @name findIndexNameByIndexKey
 * @memberof Config.PublicConfig.AlgoliaIndexMapping
 * @description Given a list of mappings, tries to convert an index key into an index name.
 */
const findIndexNameByIndexKey =
  (key: string) =>
  (mappings: Array<AlgoliaIndexMapping>): string | null | undefined =>
    mappings.find(hasIndexKey(key))?.value;

export default findIndexNameByIndexKey;
