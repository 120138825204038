import React from "react";
import { map } from "lodash/fp";
import classnames from "classnames";
import { TEXT_CLASS_NAME } from "storefront/components/Text";
import { CONTROL_CLASS_NAME } from "../Control";
import useFieldContext from "../useFieldContext";
import { Option } from "./Option";
import { OptGroup } from "./OptGroup";
import { Collection } from "./Collection";

import styles from "./SelectControl.module.scss";

type Props = {
  wrapperClassName?: string;
  className?: string;
  disabled?: boolean;
  options: Collection;
  placeholder?: string;
  required?: boolean;
  style?: React.CSSProperties;
  ariaLabel?: string;
};

export const placeholderOption = (placeholder: string): Option => ({
  label: placeholder,
  value: "",
  disabled: true,
});

const renderOption = (opt: Option | OptGroup) => {
  if (opt.options && opt.options instanceof Array) {
    return (
      <optgroup label={opt.label} disabled={opt.disabled} key={opt.label}>
        {map(renderOption)(opt.options)}
      </optgroup>
    );
  }

  if (typeof opt.value === "string") {
    return (
      <option value={opt.value} disabled={opt.disabled} key={opt.value}>
        {opt.label}
      </option>
    );
  }

  throw new Error("Can only render Option or OptGroup.");
};

const SelectControl = ({
  wrapperClassName,
  className,
  disabled,
  options,
  placeholder,
  required,
  style,
  ariaLabel,
}: Props) => {
  const { input, meta } = useFieldContext<string>();

  return (
    <div
      className={classnames(
        styles.selectControl,
        "Field-SelectControl",
        CONTROL_CLASS_NAME,
        wrapperClassName,
        { [styles.disabled]: disabled },
      )}
      style={style}
    >
      <select
        className={classnames(
          styles.input,
          TEXT_CLASS_NAME,
          CONTROL_CLASS_NAME,
          "Field-SelectControl-Input",
          className,
          {
            _error: meta.error && meta.touched,
          },
          {
            [styles.empty]: !input.value,
          },
        )}
        disabled={disabled}
        form={meta.form || ""}
        id={input.name}
        name={input.name}
        onBlur={input.onBlur}
        onChange={(event) => input.onChange(event.target.value)}
        onFocus={input.onFocus}
        required={required}
        value={input.value}
        aria-label={ariaLabel ?? input.name}
      >
        {typeof placeholder === "string"
          ? renderOption(placeholderOption(placeholder))
          : null}

        {map(renderOption)(options)}
      </select>
    </div>
  );
};

export default SelectControl;
