// This is inspired by ReduxForm's Input Props
// https://redux-form.com/8.3.0/docs/api/field.md/#input-props
import type { PostalAddress } from "storefront/PostalAddress";
import { emptyPostalAddress } from "storefront/PostalAddress";

export type Input<Value, Element extends HTMLElement = HTMLElement> = {
  name: string;
  onBlur: (event: React.FocusEvent<Element> | null | undefined) => unknown;
  onChange: (value: Value) => unknown;
  onDragStart: (event: React.DragEvent<Element>) => unknown;
  onDrop: (event: React.DragEvent<Element>) => unknown;
  onFocus: (event: React.FocusEvent<Element> | null | undefined) => unknown;
  value: Value;
};
export function empty<V, E extends HTMLElement>(value: V): Input<V, E> {
  return {
    name: "",
    onBlur: () => {},
    onChange: () => {},
    onDragStart: () => {},
    onDrop: () => {},
    onFocus: () => {},
    value,
  };
}
export const stringEmpty: Input<string> = empty("");
export const numberEmpty: Input<number> = empty(0);
export const booleanEmpty: Input<boolean> = empty(false);
export const addressEmpty: Input<PostalAddress> = empty(emptyPostalAddress);
