import type { PublicConfig } from "./index";
import "./index";
import filterAlgoliaIndexMappingsByType from "./AlgoliaIndexMappings/filterByType";
import findIndexNameByIndexKey from "./AlgoliaIndexMapping/findIndexNameByIndexKey";

/**
 * @name findAlgoliaIndexName
 * @memberof Config.PublicConfig
 * @description An Algolia index name can be used by an Algolia client to initialize an Algolia index. In the public config, we provide a mapping of index keys to index names. We provide this mapping so that we can have environment- and marketplace-specific index names.
 *
 * @param indexType {string} the index type refers to the kind of thing in the index
 * @param indexKey {string} the index key is the shorthand we use around the codebase to refer to the index, it usually has a form that includes the property on which the index is sorted and the direction of the sort; e.g. 'heat desc', 'price asc', etc
 * @returns {?string} the returned index name can be given to an Algolia client to create an Algolia index
 */
const findAlgoliaIndexName =
  (indexType: string, indexKey: string) =>
  (config: PublicConfig): string | null | undefined => {
    const { indexes } = config.algolia;
    const mappings = filterAlgoliaIndexMappingsByType(indexType)(indexes);
    return findIndexNameByIndexKey(indexKey)(mappings);
  };

/**
 * @name getAlgoliaIndexName
 * @memberof Config.PublicConfig
 * @deprecated
 * @description The exact same function as above, but guaranteed to return a string. Please try not to use this function and accept that sometimes you might not have the index name you're looking for! We are really only including it for legacy reasons.
 */
export const getAlgoliaIndexName =
  (indexType: string, indexKey: string) =>
  (config: PublicConfig): string => {
    const indexName = findAlgoliaIndexName(indexType, indexKey)(config);
    if (!indexName)
      throw new Error(
        `Missing Algolia Index for type ${indexType} and key ${indexKey}.`,
      );
    return indexName;
  };
export default findAlgoliaIndexName;
