import { createContext, useContext } from "react";
import type { Input } from "./Input";
import { empty as emptyInput } from "./Input";
import type { Meta } from "./Meta";
import { empty as emptyMeta } from "./Meta";

export type FieldContext<Value, Element extends HTMLElement = HTMLElement> = {
  input: Input<Value, Element>;
  meta: Meta<Value>;
};
// NOTE: We use `any` here and below because we can't know the types that will end up being used by any Field that consumes this context.

const emptyContext: FieldContext<any, any> = {
  input: emptyInput(""),
  meta: emptyMeta,
};

export const Context = createContext<FieldContext<any, any>>(emptyContext);
Context.displayName = "FieldContext";
export const { Provider } = Context;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type 'Pro... Remove this comment to see the full error message
Provider.displayName = "FieldProvider";

// NOTE: For some reason, Prettier doesn't like the generic type default assignment going on here,
// but it's completely valid Flow typing.
// eslint-disable-next-line prettier/prettier
function useFieldContext<
  V,
  E extends HTMLElement = HTMLElement,
>(): FieldContext<V, E> {
  return useContext(Context);
}

export default useFieldContext;
