import useMediaQuery from "storefront/hooks/useMediaQuery";

type PrefersReducedMotion = "unknown" | "reduce" | "no-preference";

/**
 * @description This hook makes it easy to limit non-essential motion in our React components!
 *
 * Visitors can configure their browser to request that websites minimize the amount of
 * non-essential motion used. Browsers provide the prefers reduced motion media query so that CSS
 * and JS can detect this preference and adjust behavior accordingly.
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
const usePrefersReducedMotion = (): PrefersReducedMotion => {
  const matches = useMediaQuery("(prefers-reduced-motion)");

  switch (matches) {
    case true:
      return "reduce";

    case false:
      return "no-preference";

    case null:
    case undefined:
    default:
      return "unknown";
  }
};

export default usePrefersReducedMotion;
