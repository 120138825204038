import Poster from "./Poster";

/**
 * @description This type represents a video that is used if its media query matches.
 */
type Source =
  | {
      src: string;
      type: string;
      srcSet?: never;
      poster?: string;
      media?: string;
      description?: string;
    }
  | {
      src?: never;
      type?: never;
      srcSet: Array<{
        src: string;
        type: string;
      }>;
      poster?: string;
      media?: string;
      description?: string;
    };

export default Source;

export const getPoster = (source: Source): Poster | undefined =>
  source.poster
    ? {
        src: source.poster,
        media: source.media,
        description: source.description,
      }
    : undefined;
