import type { AlgoliaIndexMapping } from "../AlgoliaIndexMapping";
import "../AlgoliaIndexMapping";
import type { AlgoliaIndexMappings } from "./index";
import "./index";

/**
 * @name filterByType
 * @memberof Config.PublicConfig.AlgoliaIndexMappings
 * @description Filters the collection of all of the mappings down to only the mappings for indexes containing the given type. Right now, this is very straightforward because of how the data is structured, but we don't have to expose that structure to the rest of the world.
 */
export const filterByType =
  (indexType: string) =>
  (mappings: AlgoliaIndexMappings): Array<AlgoliaIndexMapping> =>
    mappings[indexType] || [];
export default filterByType;
