import { useEffect, useRef } from "react";

/**
 * @name usePrevious
 * @description A React hook that returns the previous props, state or any other calculated value
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * Example:
  function Counter() {
    const [count, setCount] = useState(0);
    const prevCount = usePrevious(count);
    return <h1>Now: {count}, before: {prevCount}</h1>;
  }
 */
function usePrevious<V>(value: V): V {
  const ref = useRef<V>(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
