export type PageViewedEvent = {
  object: "page";
  action: "viewed";
  properties: {
    nonInteraction: 1;
  };
};

/**
 * @description Creates a "page view" event that can be given to `track` to record the event with our third-party event tracking services.
 *
 * @example
 *   const event = pageView();
 *   track(event);
 */
const pageView = (): PageViewedEvent => ({
  object: "page",
  action: "viewed",
  properties: {
    nonInteraction: 1,
  },
});

export default pageView;
