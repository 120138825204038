import { stringify } from "qs";

type Focus =
  | "center"
  | "top"
  | "right"
  | "left"
  | "bottom"
  | "top_right"
  | "top_left"
  | "bottom_right"
  | "bottom_left"
  | "face"
  | "faces";

type Fit = "pad" | "fill" | "scale" | "crop" | "thumb";

type Format = "jpg" | "png" | "webp" | "gif" | "avif";

export type Params = {
  width?: number;
  height?: number;
  focus?: Focus;
  fit?: Fit;
  format?: Format;
  quality?: number;
};

/**
 * @name contentfulImageSrc
 * @description Generates full Contentful URLs given a base URL and some parameters.
 *  This function currently accepts the following parameters: width, height, focus,
 *  fit, format, and quality. However, the Contentful URLs support many more
 *  parameters which can be found in the documentation here:
 *  https://www.contentful.com/developers/docs/references/images-api/.
 *
 * @returns {string} - the full Contentful URL with specified params
 */
const contentfulImageSrc = (
  url: string,
  { width, height, focus, fit, format, quality }: Params = {},
): string => {
  const params = {};

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'w' does not exist on type '{}'.
  if (width) params.w = width.toString();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'h' does not exist on type '{}'.
  if (height) params.h = height.toString();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'f' does not exist on type '{}'.
  if (focus) params.f = focus;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'fit' does not exist on type '{}'.
  if (fit) params.fit = fit;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'fm' does not exist on type '{}'.
  if (format) params.fm = format;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'q' does not exist on type '{}'.
  if (quality) params.q = quality.toString();

  const stringifiedParams = stringify(params);
  return `${url}${stringifiedParams ? "?" : ""}${stringifiedParams}`;
};

export default contentfulImageSrc;
