import React from "react";
import cn from "classnames";
import { Icon } from "storefront/Icon";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Caret.module.scss";

type Props = Icon & {
  direction?: "down" | "left" | "right" | "up";
};

const Caret = ({ direction = "down", width, height, className }: Props) => (
  <svg
    width={width || "14px"}
    height={height || "8px"}
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn(className, styles[`_rotate-${direction}`])}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68934 5.75L12.4697 0.969666L13.5303 2.03033L8.31066 7.25L5.68934 7.25L0.469675 2.03033L1.53033 0.969665L6.31066 5.75L7.68934 5.75Z"
      fill="currentColor"
    />
  </svg>
);

export default Caret;
