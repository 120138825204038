import classnames from "classnames";
import React from "react";

import { TEXT_CLASS_NAME } from "storefront/components/Text";
import useFieldContext from "storefront/components/Field/useFieldContext";

import styles from "./Label.module.scss";

type Size = "small" | "large" | "extra-small";

type Props = {
  children: React.ReactNode;
  className?: string;
  size: Size;
  style?: React.CSSProperties;
};

function Label({ className, children, size, style }: Props) {
  const { input, meta } = useFieldContext<unknown>();
  const finalClassName = classnames(
    TEXT_CLASS_NAME,
    styles.label,
    {
      [styles.error]: meta.error && meta.touched,
      [styles.xs]: size === "extra-small",
      [styles.sm]: size === "small",
      [styles.lg]: size === "large",
    },
    className,
  );
  return (
    <label
      className={finalClassName}
      htmlFor={input.name}
      style={style}
      data-control-type="label"
    >
      {children}
    </label>
  );
}

export default Label;
