type BuildURLParams = {
  w?: number;
  h?: number;
  orient?: number;
  fit?: Fit;
  crop?: string;
  rotate?: number;
  q?: number;
  dpr?: number;
  auto?: string;
};

function buildURL(src: string, params?: BuildURLParams): string {
  const url = new URL(src);

  if (params) {
    Object.keys(params).forEach((key: keyof BuildURLParams) => {
      const value = params[key];

      if (Array.isArray(value)) {
        url.searchParams.set(key, value.join(","));
      } else if (value) {
        url.searchParams.set(key, value.toString());
      }
    });
  }

  return url.toString();
}

type Crop =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "faces"
  | "focalpoint"
  | "edges"
  | "entropy";

type Fit =
  | "clamp"
  | "clip"
  | "crop"
  | "facearea"
  | "fill"
  | "fillmax"
  | "min"
  | "max"
  | "scale";

export type Params = {
  width?: number;
  height?: number;
  fit?: Fit;
  crop?: Crop | Array<Crop>;
  rotate?: number;
  quality?: number;
  dpr?: number;
};

const grailedImageSrc = (
  url: string,
  { width, height, fit, crop, rotate, quality, dpr }: Params = {},
): string =>
  buildURL(url, {
    w: width,
    h: height,
    fit,
    crop: Array.isArray(crop) ? crop.join(",") : crop,
    // Don't pass 0 as a value for rotate, as it will cause the image to be incorrectly rotated
    orient: rotate || undefined,
    q: quality,
    dpr,
    auto: "format",
  });

export default grailedImageSrc;
