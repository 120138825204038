import type { AlgoliaIndexMapping } from "./index";
import "./index";
import getIndexKey from "./getIndexKey";

/**
 * @name hasIndexKey
 * @memberof Config.PublicConfig.AlgoliaIndexMapping
 */
const hasIndexKey =
  (key: string) =>
  (mapping: AlgoliaIndexMapping): boolean =>
    getIndexKey(mapping) === key;

export default hasIndexKey;
