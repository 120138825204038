import React from "react";
import cn from "classnames";
import { TEXT_CLASS_NAME } from "storefront/components/Text";
import { CONTROL_CLASS_NAME } from "../Control";
import useFieldContext from "../useFieldContext";
import { Autocomplete } from "../Autocomplete";

type Props = {
  autocomplete?: Autocomplete;
  className?: string;
  disabled?: boolean;
  maxlength?: number;
  minlength?: number;
  pattern?: RegExp;
  placeholder?: string;
  required?: boolean;
  style?: React.CSSProperties;
  inputRef?: (element: HTMLInputElement | null) => void;
};

const TextControl = ({
  autocomplete,
  className,
  disabled,
  maxlength,
  minlength,
  pattern,
  placeholder,
  required,
  style,
  inputRef,
}: Props) => {
  const { input, meta } = useFieldContext<string>();

  const finalClassName = cn(TEXT_CLASS_NAME, CONTROL_CLASS_NAME, className, {
    _error: meta.error && meta.touched,
  });

  return (
    <input
      autoComplete={autocomplete}
      className={finalClassName}
      data-cy={input.name}
      disabled={disabled}
      form={meta.form || ""}
      id={input.name}
      maxLength={maxlength}
      name={input.name}
      minLength={minlength}
      onBlur={input.onBlur}
      onChange={(event) => input.onChange(event.target.value)}
      onFocus={input.onFocus}
      pattern={pattern ? pattern.toString() : undefined}
      placeholder={placeholder}
      required={required}
      style={style}
      type="text"
      value={input.value}
      ref={inputRef}
      aria-label={input.name}
    />
  );
};

export default TextControl;
