/**
 * @name standardizeUrl
 * @description Standardizes a string URL's protocol and hostname
 * @param {string} url
 * @returns {string} - the standardized string URL
 */
export const standardizeUrl = (url: string) => {
  const cleaned = url.replace(/^(https?:)?(\/+)?(www\.)?/, "");
  return `https://www.${cleaned}`;
};

/**
 * @name parseDomain
 * @description Extracts the domain from a URL
 * @param {string | URL} url
 * @returns {string | null} - the domain or null
 */
export const parseDomain = (url: string | URL) => {
  let hostname;

  try {
    if (typeof url === "string") {
      hostname = new URL(standardizeUrl(url)).hostname;
    } else {
      hostname = url.hostname;
    }
  } catch (_e) {
    return null;
  }

  return hostname.replace("www.", "");
};
