import React from "react";

import { Provider } from "../useFieldContext";
import type { Input } from "../Input";
import type { Meta } from "../Meta";
import WrapperBase from "../WrapperBase/WrapperBase";

type Props<Value> = {
  active?: boolean;
  asyncValidating?: boolean;
  autofilled?: boolean;
  children: React.ReactNode;
  className?: string;
  "data-cy"?: string;
  dirty?: boolean;
  dispatch?: ((...args: Array<any>) => any) | null | undefined;
  error?: string;
  form?: string;
  initial?: Value;
  invalid?: boolean;
  name: string;
  onBlur?: (event: React.FocusEvent<Element> | null | undefined) => unknown;
  onChange: (value: Value) => unknown;
  onDragStart?: (event: React.DragEvent<Element>) => unknown;
  onDrop?: (event: React.DragEvent<Element>) => unknown;
  onFocus?: (event: React.FocusEvent<Element> | null | undefined) => unknown;
  pristine?: boolean;
  style?: Record<string, any>;
  submitFailed?: boolean;
  submitting?: boolean;
  touched?: boolean;
  valid?: boolean;
  value: Value;
  visited?: boolean;
  warning?: string;
};

function Wrapper<V>({
  active = false,
  asyncValidating = false,
  autofilled = false,
  children,
  className,
  "data-cy": dataCy,
  dirty = false,
  dispatch = null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  error = null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  form = null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'V'.
  initial = null,
  invalid = false,
  name,
  onBlur = () => {},
  onChange,
  onDragStart = () => {},
  onDrop = () => {},
  onFocus = () => {},
  pristine = false,
  style,
  submitFailed = false,
  submitting = false,
  touched = false,
  valid = false,
  value,
  visited = false,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  warning = null,
}: Props<V>) {
  const input: Input<V> = {
    name,
    onBlur,
    onChange,
    onDragStart,
    onDrop,
    onFocus,
    value,
  };
  const meta: Meta<V> = {
    active,
    autofilled,
    asyncValidating,
    dirty,
    dispatch,
    error,
    form,
    initial,
    invalid,
    pristine,
    submitting,
    submitFailed,
    touched,
    valid,
    visited,
    warning,
  };

  return (
    <Provider
      value={{
        input,
        meta,
      }}
    >
      <WrapperBase className={className} style={style} dataCy={dataCy}>
        {children}
      </WrapperBase>
    </Provider>
  );
}

export default Wrapper;
