import React from "react";
import { Icon as IconProps } from "storefront/Icon";

const X = ({ className }: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M13.8943 1.16637L12.8336 0.105713L7 5.93934L1.16637 0.105713L0.105713 1.16637L5.93934 7L0.105713 12.8336L1.16637 13.8943L7 8.06066L12.8336 13.8943L13.8943 12.8336L8.06066 7L13.8943 1.16637Z" />
  </svg>
);

export default X;
