import type { AlgoliaIndexMapping } from "./index";
import "./index";

/**
 * @name getIndexKey
 * @memberof Config.PublicConfig.AlgoliaIndexMapping
 * @description Returns the index key from a mapping. The index key is the value used throughout the codebase to reference the index. We convert the key into an index name before going to Algolia.
 */
const getIndexKey = (mapping: AlgoliaIndexMapping): string => mapping.name;

export default getIndexKey;
