/**
 * This file is just a way for us to import these shared styles through webpack so that
 * they are not duplicated in our css.
 * Using things like @extends doesn't work in the context of Webpack modules.
 * In order to insure we can declare explicit dependency on these styles,
 * while also making sure they are only imported once,
 * we can use this js file to export a class name
 * (so we know which components need this class)
 * and import the styles in JS instead of SASS so that Webpack can treat it as a module
 */
import "./styles.scss";

export const TEXT_CLASS_NAME: "Text" = "Text";
