import React from "react";
import { Icon } from "storefront/Icon";

const LEFT_PATH =
  "M2.25 8.31066L9.03033 1.53033L7.96967 0.469666L0.750005 7.68934V10.3107L7.96967 17.5303L9.03033 16.4697L2.25 9.68934V8.31066Z";

const RIGHT_PATH =
  "M7.75 8.31066L0.969673 1.53033L2.03033 0.469666L9.25 7.68934V10.3107L2.03033 17.5303L0.969673 16.4697L7.75 9.68934V8.31066Z";

type Props = Icon & {
  left?: boolean;
};

const Chevron = ({ left, width, height, className }: Props) => (
  <svg
    width={width || "10px"}
    height={height || "18px"}
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={left ? LEFT_PATH : RIGHT_PATH}
      fill="black"
    />
  </svg>
);

export default Chevron;
