import React from "react";
import cn from "classnames";

import AutoplayVideo from "../AutoplayVideo";

import styles from "./Logo.module.scss";

export default function Logo({ className }: { className?: string }) {
  return (
    <AutoplayVideo
      data-testid="logo"
      className={cn(styles.logo, className)}
      sources={[]}
      fallback={{
        srcSet: [
          {
            src: "https://assets.grailed.com/logo.webm",
            type: "video/webm",
          },
          {
            src: "https://assets.grailed.com/logo.mp4",
            type: "video/mp4",
          },
        ],
        poster: "https://assets.grailed.com/logo.jpg",
        description: "Grailed logo",
      }}
    />
  );
}
