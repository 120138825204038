import React, { useEffect, useState } from "react";
import { flow, map, filter, uniq } from "lodash/fp";
import isPresent from "../../lib/isPresent";
import Props from "./Props";
import Source from "./Source";
import Fallback from "./Fallback";

const WantsMotion = ({ className, sources, fallback, ...props }: Props) => {
  const [mediaQueries, setMediaQueries] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const queries = flow(
      map((source: Source) => source.media),
      filter(isPresent),
      uniq,
      map((media) => window.matchMedia(media)),
    )(sources);

    const onChange = (event: MediaQueryListEvent) => {
      setMediaQueries((old) => ({
        ...old,
        [event.media]: event.matches,
      }));
    };

    queries.forEach((query) => {
      setMediaQueries((old) => ({
        ...old,
        [query.media]: query.matches,
      }));

      query.addEventListener("change", onChange);
    });

    return () => {
      queries.forEach((query) => {
        query.removeEventListener("change", onChange);
      });
    };
  }, [sources]);

  const bestSource: Source | Fallback =
    sources.find((source) =>
      source.media ? mediaQueries[source.media] : true,
    ) || fallback;

  return bestSource.srcSet ? (
    <video
      poster={bestSource.poster}
      className={className}
      autoPlay
      loop
      muted
      playsInline
      {...props}
    >
      {bestSource.srcSet.map((source) => (
        <source key={source.src} src={source.src} type={source.type} />
      ))}
    </video>
  ) : (
    <video
      src={bestSource.src}
      poster={bestSource.poster}
      className={className}
      autoPlay
      loop
      muted
      playsInline
      {...props}
    />
  );
};

export default WantsMotion;
