import React from "react";
import classnames from "classnames";
import { TEXT_CLASS_NAME } from "storefront/components/Text";
import { CONTROL_CLASS_NAME } from "../Control";
import useFieldContext from "../useFieldContext";
import type { Autocomplete } from "../Autocomplete";

type Props = {
  autocomplete?: Autocomplete;
  className?: string;
  disabled?: boolean;
  maxlength?: number;
  minlength?: number;
  pattern?: RegExp;
  placeholder?: string;
  required?: boolean;
  style?: Record<string, any>;
};

const TelephoneControl = ({
  autocomplete,
  className,
  disabled,
  maxlength,
  minlength,
  pattern,
  placeholder,
  required,
  style,
}: Props) => {
  const { input, meta } = useFieldContext<string>();
  const finalClassName = classnames(
    TEXT_CLASS_NAME,
    CONTROL_CLASS_NAME,
    "Field-TelephoneControl",
    className,
    {
      _error: meta.error && meta.touched,
    },
  );
  return (
    <input
      autoComplete={autocomplete}
      className={finalClassName}
      disabled={disabled}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
      form={meta.form}
      id={input.name}
      maxLength={maxlength}
      name={input.name}
      minLength={minlength}
      onBlur={input.onBlur}
      onChange={(event) => input.onChange(event.target.value)}
      onFocus={input.onFocus}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'RegExp | undefined' is not assignable to typ... Remove this comment to see the full error message
      pattern={pattern}
      placeholder={placeholder}
      required={required}
      style={style}
      type="tel"
      value={input.value}
    />
  );
};

export default TelephoneControl;
