import React from "react";
import classNames from "classnames";
import { TEXT_CLASS_NAME } from "../Text";
import styles from "./Footnote.module.scss";

type Props = {
  as?: keyof JSX.IntrinsicElements;
  href?: string;
  target?: string;
  className?: string;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const Footnote = ({ as: As = "p", className, ...props }: Props) => (
  <As // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={classNames(TEXT_CLASS_NAME, styles.footnote, className)}
  />
);

export default Footnote;
