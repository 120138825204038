import { isIOSApp } from "storefront/lib/Navigator";

type Platform = "iOS" | "Mobile Web" | "Web";

export default Platform;

export const fromWindow = (win: typeof window): Platform => {
  if (isIOSApp(win.navigator)) {
    return "iOS";
  }

  if (win.matchMedia("(max-device-width: 1058px)").matches) {
    return "Mobile Web";
  }

  return "Web";
};
