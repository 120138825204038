import React from "react";
import Props from "./Props";
import isPresent from "../../lib/isPresent";
import { getPoster } from "./Source";

const ReducedMotion = ({ className, sources, fallback, ...props }: Props) => {
  const posters = sources.map(getPoster).filter(isPresent);

  return (
    <picture>
      {posters.map((poster, index) => (
        <source srcSet={poster.src} key={index} media={poster.media} />
      ))}

      <img
        src={fallback.poster}
        alt={fallback.description}
        className={className}
        {...props}
      />
    </picture>
  );
};

export default ReducedMotion;
