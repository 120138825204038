import { useState, useEffect } from "react";
// These are our canonical device sizes, here for your convenience.
export const MOBILE = "(max-width: 480px)";
export const LARGER_THAN_MOBILE = "(min-width: 481px)";
export const TABLET = "(min-width: 481px) and (max-width: 770px)";
export const LARGER_THAN_TABLET = "(min-width: 771px)";
export const LAPTOP = "(min-width: 771px) and (max-width: 1024px)";
export const LARGER_THAN_LAPTOP = "(min-width: 1025px)";
export const DESKTOP = "(min-width: 1025px)";

const useMediaQuery = (query: string): boolean | null => {
  const [matches, setMatches] = useState<boolean | null>(null);
  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const currentMatch = mediaQuery.matches;

    // there's a chance that the initial render doesn't happen in a window env (ssr)
    // when the component mounts we check if the mediaquery has changed
    if (currentMatch !== matches) {
      setMatches(currentMatch);
    }

    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches);

    mediaQuery.addListener(onChange);
    return () => mediaQuery.removeListener(onChange);
  }, [matches, query]);
  return matches;
};

export default useMediaQuery;
