import type { Device } from "../lib/Device";
import useMediaQuery, {
  MOBILE,
  TABLET,
  LAPTOP,
  DESKTOP,
} from "./useMediaQuery";

const useCurrentDevice = (): Device => {
  const isMobile = useMediaQuery(MOBILE);
  const isTablet = useMediaQuery(TABLET);
  const isLaptop = useMediaQuery(LAPTOP);
  const isDesktop = useMediaQuery(DESKTOP);
  if (isMobile) return "mobile";
  if (isTablet) return "tablet";
  if (isLaptop) return "laptop";
  if (isDesktop) return "desktop";
  return "desktop";
};

export default useCurrentDevice;
