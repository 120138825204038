import React from "react";
import Clear from "storefront/components/Icons/Navigation/X";
import Chevron from "storefront/components/Icons/Navigation/Chevron";

type Props = {
  imgSrc?: string;
  imgAlt?: string;
  leftArrowText?: string;
  onRequestClose?: (e: React.SyntheticEvent<EventTarget>) => void;
  title: React.ReactNode;
  onLeftArrowClick?: () => void;
};

type CoverImageProps = {
  imgSrc: string;
  imgAlt?: string;
};

const CoverImage = ({ imgSrc, imgAlt }: CoverImageProps) => (
  <div className="-cover-img-container">
    <img className="-cover-img" src={imgSrc} alt={imgAlt} />
  </div>
);

type CloseButtonProps = {
  onRequestClose: (e: React.SyntheticEvent<EventTarget>) => void;
};

const CloseButton = ({ onRequestClose }: CloseButtonProps) => (
  <div className="-close-btn-container">
    <button
      onClick={onRequestClose}
      className="-close-btn"
      title="Close"
      type="button"
    >
      <Clear />
    </button>
  </div>
);

type LeftNavigationArrowProps = {
  onLeftArrowClick: () => void;
  leftArrowText?: string;
};

const LeftNavigationArrow = ({
  onLeftArrowClick,
  leftArrowText,
}: LeftNavigationArrowProps) => (
  <div className="-left-arrow-btn-container">
    <button
      onClick={onLeftArrowClick}
      className="-left-arrow-btn"
      title="Back"
      type="button"
    >
      <Chevron height="15" width="15" left />
      {leftArrowText ? (
        <span className="-left-arrow-text">{leftArrowText}</span>
      ) : null}
    </button>
  </div>
);

const Header = ({
  imgSrc,
  imgAlt,
  title,
  onRequestClose,
  onLeftArrowClick,
  leftArrowText,
}: Props) => (
  <div className="Modal-Header">
    {onLeftArrowClick ? (
      <LeftNavigationArrow
        onLeftArrowClick={onLeftArrowClick}
        leftArrowText={leftArrowText}
      />
    ) : null}
    {imgSrc ? <CoverImage imgSrc={imgSrc} imgAlt={imgAlt} /> : null}

    <h2 className="Modal-Header-Title">{title}</h2>

    {onRequestClose ? <CloseButton onRequestClose={onRequestClose} /> : null}
  </div>
);

export default Header;
