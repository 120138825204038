import classnames from "classnames";
import React from "react";
import { WRAPPER_CLASS_NAME } from "../Wrapper";

type Props = {
  className?: string;
  style?: Record<string, any>;
  dataCy?: string;
  children: React.ReactNode;
};

function WrapperBase({ className, style, dataCy, children }: Props) {
  const finalClassName = classnames(WRAPPER_CLASS_NAME, className);
  return (
    <div className={finalClassName} style={style} data-cy={dataCy}>
      {children}
    </div>
  );
}

export default WrapperBase;
