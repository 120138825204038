import React from "react";

type Props = {
  children?: React.ReactNode;
};

const Content = ({ children }: Props) => (
  <div className="Modal-Content">{children}</div>
);

export default Content;
